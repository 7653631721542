.rounds li {
  list-style-type: none;
}

.rounds li NavLink{
  border-left: 2px solid transparent;
  padding-left: 3px;
}

.rounds li NavLink:hover {
  border-left: blue 2px solid;
  cursor: pointer;
}

.rounds .selected {
  color: orange;
  border-left: orange 2px solid;
}

.competition-player-games .won {
  background-color: green;
}

.competition-player-games .lost {
  background-color: red;
}

.crosstable td:nth-child(n+3) {
  text-align: middle;
}

.crosstable .black {
  background-color: black;
  color: black;
}